import axios from 'axios'
import { useEffect, useState } from 'react'
import { endpoints } from '../constants/endpoints'

export const useLogginChecker = (): {
  isLoading: boolean
  isAuthenticated: boolean
} => {
  const [isLoading, setLoading] = useState(true)
  const [isAuthenticated, setAuth] = useState(false)

  useEffect(() => {
    const fetchLogged = async () => {
      const isLogged = await axios
        .get(endpoints['amlogged'], {
          validateStatus: (status) => status < 500,
        })
        .then((resp) => {
          if (resp.status <= 400) {
            return true
          } else {
            return false
          }
        })
        .catch((err) => {
          console.log('Error checking logging', err)
          return false
        })

      setAuth(isLogged)
      setLoading(false)
    }

    if (!isAuthenticated) {
      fetchLogged()
    }
  }, [isAuthenticated])

  return {
    isLoading,
    isAuthenticated,
  }
}
