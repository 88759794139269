import { Button, createStyles, makeStyles } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { endpoints } from '../../constants/endpoints'
import { routes } from '../../constants/routes'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    button: {
      width: '30%',
      height: '10%',
    },
  })
)

export const Privacy: React.FC = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const [version, setVersion] = useState<string>('')

  useEffect( () => {
    axios.get(endpoints['version'])
      .then( response => {
        setVersion(response.data)
      })
  } )

  const handleAllOut = () => {
    axios
      .post(endpoints['invalidate'])
      .then((response) => {
        if (response.status < 400) {
          push(routes.home.path)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleBlockAll = () => {
    axios
      .post(endpoints['block'])
      .then((response) => {
        if (response.status < 400) {
          push(routes.home.path)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleLogOut = () => {
    axios
      .post(endpoints['logout'])
      .then((response) => {
        if (response.status < 400) {
          push(routes.home.path)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant='contained'
        onClick={handleAllOut}
      >
        Tutti fuori
      </Button>
      <Button
        className={classes.button}
        variant='contained'
        onClick={handleBlockAll}
      >
        Blocco
      </Button>
      <Button
        className={classes.button}
        variant='contained'
        onClick={handleLogOut}
      >
        Log out
      </Button>
      <div>{version}</div>
    </div>
  )
}
