import { matchPath } from 'react-router-dom'

export const routes = {
  home: {
    path: '/',
    makeUrl: () => `/`,
    pageTitle: 'Home',
    exampleParams: {},
  },
  login: {
    path: '/login',
    makeUrl: () => `/login`,
    pageTitle: 'Login',
    exampleParams: {},
  },
  entry: {
    path: '/entry/:id?',
    makeUrl: (id?: string) => (id ? `/entry/${id}` : '/entry'),
    pageTitle: 'Entry',
    exampleParams: {},
  },
  privacy: {
    path: '/privacy',
    makeUrl: () => `/privacy`,
    pageTitle: 'Privacy',
    exampleParams: {},
  },
}

type Routes = typeof routes
type RoutesKeys = keyof Routes

export const matchAppRoute = (pathname: string) => {
  const matchedKey = Object.keys(routes).find((r) => {
    const matchedUrl = matchPath(pathname, {
      path: routes[r as RoutesKeys].path,
    })
    return matchedUrl?.isExact || false
  }) as RoutesKeys
  return routes[matchedKey]
}
