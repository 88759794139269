import axios from 'axios'
import { endpoints } from '../../constants/endpoints'

export interface DayBalance {
  id: string
  day: Date
  regular: number
  special: number
  pos: number,
  expenses: number
  note: string | null
  createdAt: Date
  updatedAt: Date
}

export const createDay = async (
  day: Omit<DayBalance, 'id' | 'createdAt' | 'updatedAt'>
): Promise<void> => {
  return axios
    .post(`${endpoints['data']}`, day)
    .then((response) => {
      if (response.status >= 400) {
        throw Error('Unable to create day balance')
      }
    })
    .catch((err) => {
      throw err
    })
}

export const updateDay = async (
  day: Omit<DayBalance, 'createdAt' | 'updatedAt'>
): Promise<void> => {
  return axios
    .put(`${endpoints['data']}/${day.id}`, day)
    .then((response) => {
      if (response.status >= 400) {
        throw Error('Unable to update day balance')
      }
    })
    .catch((err) => {
      throw err
    })
}
