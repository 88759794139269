import React from 'react'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'
import { routes } from './constants/routes'
import { Entry } from './screens/Entry'
import { Home } from './screens/Home'
import { Login } from './screens/Login'
import { SnackbarProvider } from 'notistack'
import { Privacy } from './screens/Privacy'
import { useLogginChecker } from './hooks/useLogginChecker'

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <SnackbarProvider>
        <Switch>
          <Route exact path={routes.login.path} component={Login} />
          <PrivateRoutes />
        </Switch>
      </SnackbarProvider>
    </BrowserRouter>
  )
}

export const PrivateRoutes: React.FC = () => {
  const { isLoading, isAuthenticated } = useLogginChecker()

  if (isLoading) {
    console.log('Private loading')
    return <div>Loading...</div>
  }

  if (!isAuthenticated && !isLoading) {
    console.log(`Go to login ${isAuthenticated} ${isLoading}`)
    return (
      <div>
        <Link to={routes.login.path}>Go to login page</Link>
      </div>
    )
  }

  return (
    <Switch>
      <Route exact path={routes.home.path} component={Home} />
      <Route exact path={routes.entry.path} component={Entry} />
      <Route exact path={routes.privacy.path} component={Privacy} />
    </Switch>
  )
}
