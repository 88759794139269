import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/it'
import { FormControl, TextField } from '@material-ui/core'
import { SaveCancel } from './SaveCancel'
import { fetchDay } from './fetchDay'
import { useHistory, useParams } from 'react-router-dom'
import { routes } from '../../constants/routes'
import { createDay, updateDay } from './mutateDay'
import { useSnackbar } from 'notistack'

moment.locale('it')

export const Entry: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [normale, setNormal] = useState<number>(0)
  const [special, setSpecial] = useState<number>(0)
  const [pos, setPos] = useState<number>(0)
  const [expenses, setExpenses] = useState<number>(0)
  const [description, setDescription] = useState<string>('')

  useEffect(() => {
    if (!id) {
      return
    }

    fetchDay(id)
      .then((day) => {
        setSelectedDate(day.day)
        setNormal(day.regular)
        setSpecial(day.special)
        setPos(day.pos)
        setExpenses(day.expenses)
        setDescription(day.note || '')
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value)
  }

  const handleNormalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const txt = event.target.value
    let value = 0

    if (txt.length !== 0) {
      value = parseInt(event.target.value, 10)
    }
    setNormal(value)
  }

  const handleSpecialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const txt = event.target.value
    let value = 0

    if (txt.length !== 0) {
      value = parseInt(event.target.value, 10)
    }
    setSpecial(value)
  }

  const handlePosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const txt = event.target.value
    let value = 0

    if (txt.length !== 0) {
      value = parseInt(event.target.value, 10)
    }
    setPos(value)
  }

  const handleExpensesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const txt = event.target.value
    let value = 0

    if (txt.length !== 0) {
      value = parseInt(event.target.value, 10)
    }
    setExpenses(value)
  }

  const handleCancelClick = () => {
    push(routes.home.path)
  }

  const handleSaveClick = async () => {
    const data = {
      day: selectedDate,
      regular: normale,
      special: special,
      pos: pos,
      expenses: expenses,
      note: description,
    }

    await (id ? updateDay({ ...data, id }) : createDay(data))
      .then(() => {
        push(routes.home.path)
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(err.message, { variant: 'error' })
      })
  }

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale='it'
    >
      <DatePicker
        value={selectedDate}
        onChange={(date) => setSelectedDate(date?.toDate() || new Date())}
        format='dddd D MMMM YYYY'
      />

      <FormControl fullWidth>
        <TextField
          value={normale}
          onChange={handleNormalChange}
          label='Normale'
          type='number'
          size='small'
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          value={special}
          onChange={handleSpecialChange}
          label='Special'
          type='number'
          size='small'
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          value={pos}
          onChange={handlePosChange}
          label='Pos'
          type='number'
          size='small'
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          value={expenses}
          onChange={handleExpensesChange}
          label='Spese'
          type='number'
          size='small'
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          value={description}
          onChange={handleDescriptionChange}
          label='Description'
          type='text'
          size='small'
          multiline
        />
      </FormControl>

      <SaveCancel onSave={handleSaveClick} onCancel={handleCancelClick} />
    </MuiPickersUtilsProvider>
  )
}
