import {
  Card,
  CardContent,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Divider,
  Typography,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Settings as SettingsIcon,
  Add as AddIcon,
  Comment as NoteIcon
} from '@material-ui/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from '../../constants/routes'
import { DayBalance, fetchDayBalances } from './fetchDays'

const useStyles = makeStyles({
  floatingContainerRight: {
    position: 'fixed',
    bottom: '2rem',
    right: '1rem',
    zIndex: 1000,
  },
  floatingContainerLeft: {
    position: 'fixed',
    bottom: '2rem',
    left: '1rem',
    zIndex: 1000,
  },
  listSpacer: {
    height: '5rem',
  },
  sumText: {
    textAlign: 'center',
  },
})

const isValidText = (txt: string | null): boolean => txt !== null && txt !== '' 

export const Home: React.FC = () => {
  const classes = useStyles()
  const { push } = useHistory()

  const [balances, setBalances] = useState<DayBalance[]>([])
  const [fetchError, setFetchError] = useState<Error | null>(null)

  useEffect(() => {
    setFetchError(null)
    fetchDayBalances()
      .then((days) => {
        setBalances(days)
      })
      .catch((err) => {
        setFetchError(err)
      })
  }, [])

  if (fetchError) {
    return <div>Fetch error...</div>
  }

  const handleBalanceEdit = (id: string) => {
    console.log('whant to edit: ', id)
    push(routes.entry.makeUrl(id))
  }

  const handleAddClick = () => {
    console.log('whant to add')
    push(routes.entry.makeUrl())
  }

  const handlePrivacyClick = () => {
    console.log('whant to add')
    push(routes.privacy.path)
  }

  const normalSum = balances.reduce((acc, el) => acc + el.regular, 0)
  const specialSum = balances.reduce((acc, el) => acc + el.special, 0)

  return (
    <div>
      <Card>
        <CardContent>
          <Typography align='center' variant='h3'>
            {normalSum}
          </Typography>
          <Divider variant='middle' />
          <Typography align='center' variant='h3'>
            {specialSum}
          </Typography>
        </CardContent>
      </Card>
      <List dense>
        {balances.map((day, idx) => (
          <ListItem key={`balance-${idx}`}>
            <ListItemAvatar>
              <Avatar>
                <IconButton disabled={!isValidText(day.note)}>
                  <NoteIcon color={isValidText(day.note) ? 'primary' : 'inherit'}/>
                </IconButton>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={moment(day.day).locale('it').format('dddd D MMMM YYYY')}
              secondary={`${day.regular} / ${day.special} / ${day.pos} / ${day.expenses}`}
            />
            <ListItemSecondaryAction>
              <IconButton edge='end' onClick={() => handleBalanceEdit(day.id)}>
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <div className={classes.listSpacer}></div>
      <Fab
        className={classes.floatingContainerRight}
        color='primary'
        onClick={handleAddClick}
      >
        <AddIcon />
      </Fab>
      <Fab
        className={classes.floatingContainerLeft}
        color='primary'
        onClick={handlePrivacyClick}
      >
        <SettingsIcon />
      </Fab>
    </div>
  )
}
