import { Button, makeStyles, TextField } from '@material-ui/core'
import axios from 'axios'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { endpoints } from '../../constants/endpoints'
import { routes } from '../../constants/routes'
import { useLogginChecker } from '../../hooks/useLogginChecker'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
})

export const Login: React.FC = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const { isLoading, isAuthenticated } = useLogginChecker()

  const [username, setUsername] = useState<string>('')
  const [pwd, setPwd] = useState<string>('')

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isAuthenticated && !isLoading) {
    console.log('Time to redirect to home')
    return (
      <div>
        <Link to={routes.home.path}>Sei già loggato, torna alla home</Link>
      </div>
    )
  }

  const handleLogin = () => {
    console.log('login')
    axios
      .post(endpoints['login'], {
        userName: username,
        password: pwd,
      })
      .then((response) => {
        if (response.status < 400) {
          console.log('logged')
          push(routes.home.path)
        } else {
          console.log('Not logged sorry')
        }
      })
      .catch((err) => {
        console.log('Login error: ', err)
      })
  }

  const usernameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const pwdOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPwd(event.target.value)
  }

  return (
    <div className={classes.root}>
      <TextField
        id='filled-password-input'
        label='username'
        variant='outlined'
        onChange={usernameOnChange}
      />
      <TextField
        id='filled-password-input'
        label='Password'
        type='password'
        autoComplete='current-password'
        variant='outlined'
        onChange={pwdOnChange}
      />
      <Button onClick={handleLogin}>Login</Button>
    </div>
  )
}
