import React from 'react'
import { makeStyles, createStyles, Button } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    saveButton: {
      background: theme.palette.success.light,
    },
    cancelButton: {
      marginRight: theme.spacing(2),
      background: theme.palette.error.light,
    },
  })
)

export interface SaveCancelProps {
  className?: string
  isSaveDisabled?: boolean
  isCancelDisabled?: boolean
  onSave?: () => void
  onCancel?: () => void
}

export const SaveCancel: React.FC<SaveCancelProps> = ({
  className,
  onSave,
  isSaveDisabled,
  isCancelDisabled,
  onCancel,
}) => {
  const classes = useStyles()

  return (
    <div className={className}>
      <div className={classes.container}>
        <Button
          size='small'
          variant='contained'
          color='primary'
          className={classes.saveButton}
          onClick={onSave}
          disabled={isSaveDisabled || false}
          type='submit'
        >
          Save
        </Button>
        <Button
          size='small'
          variant='contained'
          color='primary'
          className={classes.cancelButton}
          onClick={onCancel}
          disabled={isCancelDisabled || false}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}
