import axios from 'axios'
import { endpoints } from '../../constants/endpoints'

export interface DayBalance {
  id: string
  day: Date
  regular: number
  special: number
  pos: number
  expenses: number
  note: string | null
  createdAt: Date
  updatedAt: Date
}

export const fetchDayBalances = async (): Promise<DayBalance[]> => {
  return axios
    .get(endpoints['data'])
    .then((response) => {
      if (response.status >= 400) {
        throw Error('Unable to fetch data')
      }

      const data = response.data as DayBalance[]
      return data
    })
    .catch((err) => {
      throw err
    })
}
