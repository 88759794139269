export const { REACT_APP_API_BASE_PATH, REACT_APP_UI_VERSION } = process.env // no trailing slash!

export const endpoints: { [key: string]: string } = {
  amlogged: '/api/auth/amlogged',
  login: '/api/auth/login',
  logout: '/api/auth/logout',
  data: '/api/data',
  invalidate: '/api/admin/secret/invalidate',
  block: '/api/admin/requests/block',
  version: '/api/version',
}

export const appInfo = {
  version: REACT_APP_UI_VERSION,
}
